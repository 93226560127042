<template>
  <div class="page-layout">
    <page-layout-header
      :is-user-logged-in="showProfile"
      @logout="$emit('logout')"
      @goToProfile="goToProfile"
    />
    <h1
      v-if="hasTitle"
      class="page-layout__title"
    >
      {{ title }}
    </h1>
    <div class="page-layout__content">
      <slot />
    </div>
  </div>
</template>

<script>
import { ROUTER_PATHS } from '@/router/paths';
import PageLayoutHeader from '../PageLayoutHeader/PageLayoutHeader.vue';

export default {
    components: {
        PageLayoutHeader,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
    },
    computed: {
        showProfile() {
            return this.$store.state.user_info_store.showProfile;
        },
        hasTitle() {
            return this.title !== '';
        },
    },
    methods: {
        goToProfile() {
            this.$router.push(ROUTER_PATHS.USER_ACCOUNT);
        },
    },
};
</script>

<style src="./PageLayout.scss" lang="scss">
</style>