<template>
  <div class="app-inner">
    <NotificationBanner />
    <PageLayout
      v-if="websocketFail"
      :title="$t('loginsuccess.redirect.title')"
    >
      <p class="login-success__paragraph">
        <span class="login-success__text">
          {{ $t('loginsuccess.redirect.desc') }}
        </span>
      </p>
      <base-button
        :customizable="{ maxWidth: '350px' }"
        :is-type="['simple', 'vanish']"
        data-testid="open-voicemod-button"
        :is-disabled="buttonDisabled"
        @onClick="onCLickAction"
      >
        {{ $t('loginsuccess.button') }}
      </base-button>
      <a
        v-if="buttonDisabled"
        class="simple-link login-success__link"
        :href="`${loginErrorHelpUrl}?source=account_login`"
        target="_blank"
      >
        {{ $t('loginsuccess.support') }}
      </a>
    </PageLayout>
    <PageLayout
      v-else
      :title="$t('loginsuccess.title')"
    >
      <p class="login-success__paragraph">
        <span class="login-success__text">
          {{ $t('loginsuccess.subtitle') }}
        </span>
      </p>
      <base-button
        :customizable="{ maxWidth: '350px' }"
        :is-type="['simple', 'vanish']"
        data-testid="open-voicemod-button"
        :is-disabled="buttonDisabled"
        @onClick="onCLickAction"
      >
        {{ $t('loginsuccess.button') }}
      </base-button>
      <a
        v-if="buttonDisabled"
        class="simple-link login-success__link"
        :href="`${loginSuccessHelpUrl}?source=account_login`"
        target="_blank"
      >
        {{ $t('loginapp.support') }}
      </a>
    </PageLayout>
  </div>
</template>

<script>
import { mParticleMetric } from '@/metrics';
import { mapState } from 'vuex';
import { WebSocketClient } from '@/services/websocket-client';
import { AuthClient } from '@/services/models/authentication';
import { LOGIN_ACTIONS_CONSTANTS } from '@/domain/login';
import { ROUTER_PATHS } from '@/router/paths';
import { URL_CONSTANTS } from '@/externalURLs';
import PageLayout from '@/components/PageLayout/PageLayout.vue';
import BaseButton from '@RepoComponent/BaseButton/BaseButton';
import NotificationBanner from '@/components/NotificationBanner/NotificationBanner';

const REDIRECT_TO = {
    [LOGIN_ACTIONS_CONSTANTS.DOWNLOAD]: ROUTER_PATHS.ACCOUNT_ACTIVATED_SUCCESS,
    [LOGIN_ACTIONS_CONSTANTS.CHECKOUT]: ROUTER_PATHS.CHECKOUT,
};

export default {
    components: {
        PageLayout,
        BaseButton,
        NotificationBanner,
    },

    data() {
        return {
            buttonDisabled: false,
            code: this.$route.query.code,
            userId: this.$route.query.userId,
            isNew: this.$route.query.isNew,
            isNewLogin: localStorage.newLogin,
            hasRedirect: false,
            desktopCode: null,
            loginSuccessHelpUrl: URL_CONSTANTS.LOGIN_SUCCESS_HELP,
            loginErrorHelpUrl: URL_CONSTANTS.LOGIN_ERROR_HELP,
        };
    },

    computed: {
        ...mapState({
            websocketFail: (state) => state.websocket_store.websocket.fail,
            websocketPorts: (state) => state.websocket_store.websocket.ports,
        }),
    },

    beforeMount() {
        const { payload } = this.$route.query;

        if (!payload) {
            this.openDesktopApp();
            return;
        }

        this.redirectTo(payload);
    },

    async mounted() {
        this.$store.dispatch('user_info_store/axn_setShowProfile', true);
        mParticleMetric.track(mParticleMetric.EVENTS.LOG_IN_SUCCESS_VIEW);
    },

    methods: {
        async onCLickAction() {
            this.buttonDisabled = true;
            mParticleMetric.track(mParticleMetric.EVENTS.DEEP_LINK_CLICKED);
            const { data: desktopData } = await AuthClient.generateDesktopCode(this.userId);

            window.location.href = `voicemod:login_token&code=${desktopData.code}`;
        },

        redirectTo(payload) {
            const { redirect } = JSON.parse(payload);

            if (REDIRECT_TO[redirect]) {
                this.hasRedirect = true;
                this.$router.push({
                    path: REDIRECT_TO[redirect],
                    query: this.$route.query,
                });
                return;
            }

            this.openDesktopApp();
        },

        async openDesktopApp() {
            const { data: desktopData } = await AuthClient.generateDesktopCode(this.userId);
            const { ws:webSocketPort, channel } = this.$route.query;

            if (webSocketPort) {
                this.$store.dispatch('websocket_store/axn_setWebsocketPorts', webSocketPort);
            }

            if (desktopData.code) {

                if (channel) {
                    await AuthClient.sendSSENotifictation(channel, desktopData.code);
                }

                this.$store.dispatch('websocket_store/axn_websocketUser', {
                    userID: this.userId,
                    isNew: this.isNew,
                    code: desktopData.code,
                });

                try {
                    await WebSocketClient.connect({
                        server: 'login',
                        url: 'nlws',
                        actionType: 'loginDeepLink',
                    });
                } catch {
                    console.log('Failed to open websocket connection');
                }
            }
        },
    },
};
</script>

<style src="./LoginSuccessView.scss" lang="scss"></style>
