import { axiosClient } from '@/services/axios-client';

const getAccessToken = (code) => {
    return axiosClient.post('/oauth/token', {
        grant_type: 'authorization_code',
        code,
        client_id: process.env.VUE_APP_WEB_CLIENT_ID,
        client_secret: process.env.VUE_APP_WEB_CLIENT_SECRET,
    });
};

const getAccessTokenFromRefreshToken = (refreshToken) => axiosClient.post('/oauth/token', {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    client_id: process.env.VUE_APP_WEB_CLIENT_ID,
    client_secret: process.env.VUE_APP_WEB_CLIENT_SECRET,
});

const generateThirdPartyCode = (user_id, client) => {
    return axiosClient.post('/auth/generate_code', {
        client,
        user_id,
    });
};

const generateDesktopCode = (user_id) => {
    return axiosClient.post('/auth/generate_code', {
        client: 'windows',
        user_id,
    });
};

const generateNextgenCode = (user_id) => {
    return axiosClient.post('/auth/generate_code', {
        client: 'next-gen',
        user_id,
    });
};

const sendSSENotifictation = (channelId, code) => {
    return axiosClient.post(`https://int-sse-api.voicemod.dev/api/v1/notification/${channelId}`, {
        message: JSON.stringify({code: code}),
    },{
        withCredentials: false,
    });
};

const getLoginInfoWithEmail = (email, verificationCode, originalClient = 'voicemod') => {
    return axiosClient.post('/auth/sign-in-with-email', {
        email,
        source: 'web',
        originalClient,
        verificationCode,
    });
};

export const AuthClient = {
    getAccessToken,
    generateDesktopCode,
    generateNextgenCode,
    getLoginInfoWithEmail,
    generateThirdPartyCode,
    sendSSENotifictation,
    getAccessTokenFromRefreshToken,
};