<template>
  <header class="page-layout-header">
    <a :href="homeURL">
      <img
        class="page-layout-header__logo"
        :src="require('images/voicemod-white.svg')"
        alt="Voicemod logo"
      >
    </a>
    <div
      v-if="isUserAccountView"
      class="page-layout-header__logout"
    >
      <base-button
        :is-type="['simple', 'light', 'light-c-warning']"
        @onClick="$emit('logout')"
      >
        {{ $t('userAccount.logout') }}
      </base-button>
    </div>
    <div
      v-else-if="isUserLoggedIn"
      class="page-layout-header__account"
      @click="$emit('goToProfile')"
    >
      <base-icon
        name="account"
        size="medium"
      />
      <span class="page-layout-header__account-text">
        {{ $t('userAccount.my-account') }}
      </span>
    </div>
  </header>
</template>

<script>
import { ROUTER_PATHS } from '@/router/paths';
import { URL_CONSTANTS } from '@/externalURLs';

export default {
    components: {
        BaseIcon: () => import(/* webpackChunkName: "BaseIcon"*/ '@RepoComponent/BaseIcon/BaseIcon.vue'),
        BaseButton: () => import(/* webpackChunkName: "BaseButton"*/ '@RepoComponent/BaseButton/BaseButton.vue'),
    },
    props: {
        isUserLoggedIn: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            homeURL: URL_CONSTANTS.HOMEPAGE,
        };
    },
    computed: {
        isUserAccountView() {
            return this.$route.path.includes(ROUTER_PATHS.USER_ACCOUNT);
        },
    },
};
</script>

<style src="./PageLayoutHeader.scss" lang="scss">
</style>