<template>
  <div
    class="njt-nofi-container-content"
    style="max-width: 100%; justify-content: center; color: #0c0c0c; text-align: center;padding: 8px;background: #ffda59; line-height: 1.2;"
  >
    If you are having trouble logging in to V2, make sure you have the latest version (2.51.0.0)
    <a
      href="https://www.voicemod.net/b2c/v2/VoicemodSetup_2.51.0.0.exe"
      class="njt-nofi-button-text njt-nofi-padding-text"
      style="background: #0c0c0c; border-radius: 3px;font-weight: 500;color: #fcfcfc;display: inline-block;padding: 3px 13px;margin: 5px 0 5px 10px; line-height: 1.4"
    >Download Now!</a>
  </div>
</template>

<script>
export default {
    name: 'NotificationBanner',
};
</script>

