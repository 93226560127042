import store from '@/store';
import { mParticleMetric } from '@/metrics';

const openedWebsockets = [];
const AWAIT_APP_RESPONSE = 5000;

const connect = (config = { server: 'Kestrel', url: 'vmsd', actionType: 'loginDeepLink' }) =>
    new Promise((resolve, reject) => {
        const { server, url, actionType } = config;
        const { ports } = store.state.websocket_store.websocket;
        store.dispatch('websocket_store/axn_websocketActionType', actionType);
        openedWebsockets.length = 0;

        ports.forEach((port) => {
            const newWebSocketConnection = new WebSocket(`ws://127.0.0.1:${port}/${url}`);
            openedWebsockets.push(newWebSocketConnection);

            newWebSocketConnection.onopen = (event) => onOpenHandler(event, server, resolve, reject);
            newWebSocketConnection.onerror = () => errorHandler(reject);
        });
    });

const onOpenHandler = (event, server, resolve, reject) => {
    event.target.onmessage = (onMessageEvent) => onMessageHandler(onMessageEvent, server);
    event.target.onerror = () => errorHandler(reject);
    resolve();
};

const onMessageHandler = (event, server) => {
    const {data, target} = event;

    if (!data) {
        store.dispatch('websocket_store/axn_websocketError', { value: true });
        return;
    }

    const websocketData = JSON.parse(data);

    if (websocketData.actionType === 'noAuth') {
        failedAuth();
        return;
    }

    if (!websocketData.server) {
        store.dispatch('websocket_store/axn_websocketError', { value: true });
        return;
    }

    if (websocketData.server === server) {
        sendMessage(target);
        return;
    }

    if (websocketData.actionObject?.commandNotFound) {
        store.dispatch('websocket_store/axn_websocketError', { value: true });
        return;
    }

    closeWebsocket(target);
};

const sendMessage = (websocket) => {
    const { user, actionType, actionID } = store.state.websocket_store.websocket;
    const message = {
        actionType,
        actionObject: user || {},
        actionID,
        pluginVersion: '2.2.0',
    };

    websocket.send(JSON.stringify(message));
    closeAllConnections();
    mParticleMetric.track(mParticleMetric.EVENTS.WEBSOCKET_CONNECTED);
};

const sendBroadcastMessage = () => {
    openedWebsockets.forEach((websocket) => {
        if (isOpened(websocket)) {
            sendMessage(websocket);
        }
    });
};

const closeWebsocket = (websocket) => websocket.close();

const failedAuth = () => {
    store.dispatch('websocket_store/axn_websocketFail', { value: true });
    mParticleMetric.track(mParticleMetric.EVENTS.SIGN_IN_ERROR, {
        error_code: '01',
        error_text: 'An unexpected error has occurred',
    });
};

const errorHandler = (reject) => {
    store.dispatch('websocket_store/axn_websocketError', { value: true });
    mParticleMetric.track(mParticleMetric.EVENTS.WEBSOCKET_DISCONNECTED);
    reject();
};

const isConnecting = (websocket) => websocket.readyState === 0;

const isOpened = (websocket) => websocket.readyState === 1;

const closeAllConnections = () => {
    openedWebsockets.forEach((websocket, index) => {
        if (isConnecting(websocket)) {
            openedWebsockets.splice(index, 1);
        }
        if (isOpened(websocket)) {
            setTimeout(() => {
                closeWebsocket(websocket);
            }, AWAIT_APP_RESPONSE);
        }
    });
};

const WebSocketClient = {
    connect,
    errorHandler,
    closeWebsocket,
    openedWebsockets,
    onMessageHandler,
    closeAllConnections,
    sendBroadcastMessage,
};

export { WebSocketClient };