const URL_CONSTANTS = {
    DOWNLOAD_VOICEMOD: 'https://www.voicemod.net/downloadVoicemodJSON.php',
    DOWNLOAD_VOICEMOD_ARM64: 'https://www.voicemod.net/downloadVoicemodApple.php?a=arm64',
    DOWNLOAD_VOICEMOD_INTEL: 'https://www.voicemod.net/downloadVoicemodApple.php?a=intel',
    APPLE_STORE: 'https://apps.apple.com/app/id6473788687',
    APPLE_STORE_SOUNDBOARDAPP: 'https://apps.apple.com/cy/app/voicemod-meme-soundboard/id6473788687',
    ANDROID_STORE: 'https://play.google.com/store/apps/details?id=net.voicemod.soundboard',
    ANDROID_STORE_SOUNDBOARDAPP: 'https://play.google.com/store/apps/details?id=net.voicemod.soundboard',
    HOMEPAGE: 'https://www.voicemod.net/',
    TERMS: 'https://www.voicemod.net/terms-of-use/',
    PRIVACY: 'https://www.voicemod.net/privacy/',
    COOKIES: 'https://www.voicemod.net/cookies-policy/',
    LEGAL: 'https://www.voicemod.net/legal/',
    SUPPORT: 'https://www.voicemod.net/support/',
    LOGIN_SUCCESS_HELP: 'https://support.voicemod.net/hc/en-us/articles/360017604120-Login-Issues-stuck-on-We-re-verifying-your-account-pop-up',
    LOGIN_ERROR_HELP: 'https://support.voicemod.net/hc/en-us/articles/4406546207378-Login-Issues-LOGIN-ERROR',
    UNEXPECTED_ERROR_HELP: 'https://support.voicemod.net/hc/en-us/articles/4406387628562-Login-Issues-An-unexpected-error-has-occurred',
    XSOLLA_API: 'https://login.xsolla.com/api/social/',
    VOICEMOD_LOADING_GIF: 'https://www.voicemod.net/identidad/checkout/voicemod-loading.gif',
    SUBSCRIPTION_CANCELLED_SURVEY: 'https://voicemod.typeform.com/to/esr681UC',
    GIFT_LICENSE_PRIVACY_POLICY: 'https://voicemod.net/privacy/giftalicense',
};

export { URL_CONSTANTS };