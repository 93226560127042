const LOGIN_ACTIONS_CONSTANTS = {
    LOGIN: 'login',
    LOGOUT: 'logout',
    DOWNLOAD: 'download',
    DOWNLOAD_FLAGSHIP: 'download-flagship',
    CHECKOUT: 'checkout',
    FLAGSHIP_CHECKOUT: 'flagship-checkout',
    ACCOUNT: 'account',
    UNDEFINED: 'undefined',
    AUTHORIZE: 'authorize',
    PROMOTION: 'promotion',
    NEXTGEN: 'nextgen',
    AIBETAPROGRAM: 'aibetaprogram',
    STORE: 'store',
    TTSING: 'ttsing',
    TUNA: 'tuna',
    VMGO: 'vm-go',
    VM_SOUNDBOARD: 'vm-soundboard',
    VM_SOUNDBOARD_DEV: 'vm-soundboard-dev',
    VM_SOUNDBOARD_STA: 'vm-soundboard-sta',
    COUPON: 'coupon',
    BEG: 'beg',
    GIFT: 'gifting',
    BETAPROGRAM: 'betaprogram',
    HALLOWEEN: 'halloween',
    VOICEROOM: 'room',
    BEG_GIFTING: 'beg-gifting',
    DOWNLOAD_MOBILE: 'download-mobile',
    DOWNLOAD_MOBILE_FLAGSHIP: 'download-mobile-flagship',
};

export {
    LOGIN_ACTIONS_CONSTANTS,
};
